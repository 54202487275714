import React, { useMemo } from 'react'
import { TransactionPage } from '../../../presentation/common/components/app/Transaction/TransactionPage'
import { useLocation } from '@reach/router'
import { createRemoteGetTransaction } from '../usecases/remote-get-transaction-factory'
import { createRemoteGetTransactionReservation } from '../usecases/remote-get-transaction-reservation-factory'

const useTransactionId = (): number => {
  const location = useLocation()

  return useMemo(
    () => {
      const regex = /\/transaction\/([0-9]+)/
      const match = location.pathname.match(regex)
      return match ? parseInt(match[1]) : null
    },
    [location],
  )
}

export default () => {
  const transactionId = useTransactionId()
  const getTransaction = useMemo(() => createRemoteGetTransaction(transactionId), [transactionId])
  const getTransactionReservation = useMemo(() => createRemoteGetTransactionReservation(transactionId), [transactionId])

  return <TransactionPage
    getTransaction={getTransaction}
    getReservation={getTransactionReservation}
  />
}
