import { HttpStatusCode } from 'axios'

import { HttpClient } from '../network/http-client'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { Reservation } from '../../domain/entities/reservation'
import { IGetReservation } from '../../domain/usecases/get-reservation'

export class RemoteGetTransactionReservation implements IGetReservation {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<Reservation>) {
  }

  async get(): Promise<Reservation> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}
