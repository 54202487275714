import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useNotificationContext } from '../context-provider/notification-context-provider'
import { navigateSafely } from '../../../../utils/safe-navigation'
import { OptionContext, useOptionsContext } from './OptionsContextProvider'
import { useFlyInformationContext } from './FlyInformationContextProvider'
import { useAccountContext } from '../context-provider/account-context-provider'
import { IQuotes } from '../../../../../domain/usecases/quotes'
import { CreateReservation, ICreateReservation } from '../../../../../domain/usecases/create-reservation'
import { ICreatePendingReservation } from '../../../../../domain/usecases/create-pending-reservation'

export type CreateReservationContext = {
  reservation: any
  prices: PricingType
  setPromoCode: (value: string) => void
  setFirstname: (value: string) => void
  setLastname: (value: string) => void
  setEmail: (value: string) => void
  setMobile: (value: string) => void
  setAddress: (value: string) => void
  setZipcode: (value: string) => void
  setCity: (value: string) => void
  setCarBrand: (value: string) => void
  setRegistration: (value: string) => void
  setClientName: (value: string) => void
  setClientService: (value: string) => void
  setClientAddress: (value: string) => void
  setClientZipcode: (value: string) => void
  setClientCity: (value: string) => void

  createReservation: () => Promise<void>
  createPendingReservation: () => Promise<any>
  isLoading: boolean
}

export type PricingType = {
  isLoading: boolean
  error: string | null,
  parkDays: number | null
  parkPrice: number | null
  totalPrice: number | null
  bonusPrice: number | null
  remainingPrice: number | null
  promoCode: string | null
  promoCodeReduction: number | null
  isPaymentEnabled: boolean
  paymentRequired: boolean
  options: any[]
}

const CreateReservationContextClass = createContext({})

export const useCreateReservationContext = (): CreateReservationContext => {
  return useContext(CreateReservationContextClass) as CreateReservationContext
}

function formatReservationRequest(reservation: any, options: readonly OptionContext[]): CreateReservation.Params {
  return {
    deposit: reservation.deposit,
    pickup: reservation.pickup,
    flights: {
      depositFlyNumber: reservation.depositFlyNumber,
      pickupFlyNumber: reservation.pickupFlyNumber,
      destination: reservation.destination,
      origin: reservation.origin,
    },
    user: {
      carBrand: reservation.carBrand,
      registration: reservation.registration,
      mobile: reservation.mobile,
      email: reservation.email,
      firstname: reservation.firstname,
      lastname: reservation.lastname,
      address: reservation.address,
      zipcode: reservation.zipcode,
      city: reservation.city,
      clientName: reservation.clientName,
      clientService: reservation.clientService,
      clientAddress: reservation.clientAddress,
      clientZipcode: reservation.clientZipcode,
      clientCity: reservation.clientCity,
    },
    options: options.filter(option => option.enabled).map(option => option.id),
    promoCode: reservation.promoCode,
  }
}

const defaultPriceState: PricingType = {
  isLoading: false,
  error: null,
  parkDays: null,
  parkPrice: null,
  totalPrice: null,
  bonusPrice: null,
  remainingPrice: null,
  promoCode: null,
  promoCodeReduction: null,
  isPaymentEnabled: false,
  paymentRequired: false,
  options: [],
}

const useGetPrices = (quotes: IQuotes, deposit: any, pickup: any, options: readonly OptionContext[], code: string | null) => {
  const params = useMemo(() => ({ deposit, pickup, options, code }), [deposit, pickup, options, code])
  const [prices, setPrices] = useState<PricingType>(defaultPriceState)

  useEffect(() => {
    (async () => {
      // TODO: ignore if deposit < pickup
      if (params.deposit && params.pickup && !isNaN(params.deposit.getTime()) && !isNaN(params.pickup.getTime())) {
        try {
          setPrices({ ...prices, isLoading: true })
          const response = await quotes.get({
            deposit: params.deposit,
            pickup: params.pickup,
            options: params.options.filter(option => option.enabled).map(option => option.id),
            code: params.code,
          })
          setPrices({
            isLoading: false,
            error: response.available ? null : response.error?.message || 'parking complet à ces dates',
            parkDays: response.parkDays,
            parkPrice: response.parkPrice,
            totalPrice: response.totalPrice,
            bonusPrice: response.bonusPrice,
            remainingPrice: response.remainingPrice,
            promoCode: response.promoCode,
            promoCodeReduction: response.promoCodeReduction,
            isPaymentEnabled: response.isPaymentEnabled,
            paymentRequired: response.paymentRequired,
            options: response.options,
          })
        } catch (error) {
          console.error(error)
          setPrices(defaultPriceState)
        }
      } else {
        setPrices(defaultPriceState)
      }
    })()
  }, [params])

  return prices
}

type Props = {
  quotes: IQuotes
  createReservation: ICreateReservation
  createPendingReservation: ICreatePendingReservation
  children: React.ReactNode
}

export const CreateReservationContextProvider = ({
                                                   quotes,
                                                   createReservation,
                                                   createPendingReservation,
                                                   children,
                                                 }: Props) => {
  const { notifyError } = useNotificationContext()
  const { account } = useAccountContext()
  const [reservation, setReservation] = useState({
    promoCode: null,
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    address: '',
    zipcode: '',
    city: '',
    carBrand: '',
    registration: '',
    clientName: '',
    clientService: '',
    clientAddress: '',
    clientZipcode: '',
    clientCity: '',
  })
  const [isLoading, setLoading] = useState(false)
  const flyInformationContext = useFlyInformationContext()
  const { options } = useOptionsContext()
  const prices = useGetPrices(
    quotes,
    flyInformationContext.deposit,
    flyInformationContext.pickup,
    options,
    reservation.promoCode,
  )

  const update = (field: string) => (value: any) => {
    setReservation({ ...reservation, [field]: value })
  }

  useEffect(() => {
    setReservation({
      ...reservation,
      firstname: account?.firstname || '',
      lastname: account?.lastname || '',
      email: account?.email || '',
      mobile: account?.mobile || '',
      address: account?.address || '',
      zipcode: account?.zipcode || '',
      city: account?.city || '',
      carBrand: account?.carBrand || '',
      registration: account?.registration || '',
      clientName: account?.clientName || '',
      clientService: account?.clientService || '',
      clientAddress: account?.clientAddress || '',
      clientZipcode: account?.clientZipcode || '',
      clientCity: account?.clientCity || '',
    })
  }, [account])

  const handleCreateReservation = useCallback(async () => {
    try {
      setLoading(true)
      const response = await createReservation.create(formatReservationRequest({ ...flyInformationContext, ...reservation }, options))
      await navigateSafely(`/app/confirmation/?ref=${response.insertId}`)
    } catch (e) {
      console.error(e)
      notifyError('Une erreur est survenue')
    } finally {
      setLoading(false)
    }
  }, [createReservation, flyInformationContext, reservation, options])

  const handleCreatePendingReservation = useCallback(async () => {
    try {
      setLoading(true)
      return await createPendingReservation.create(
        formatReservationRequest({ ...flyInformationContext, ...reservation }, options),
      )
    } catch (e) {
      console.error(e)
      notifyError('Une erreur est survenue')
    } finally {
      setLoading(false)
    }
  }, [createPendingReservation, flyInformationContext, reservation, options])

  const context: CreateReservationContext = {
    reservation,
    prices,
    setPromoCode: update('promoCode'),
    setFirstname: update('firstname'),
    setLastname: update('lastname'),
    setEmail: update('email'),
    setMobile: update('mobile'),
    setAddress: update('address'),
    setZipcode: update('zipcode'),
    setCity: update('city'),
    setCarBrand: update('carBrand'),
    setRegistration: update('registration'),
    setClientName: update('clientName'),
    setClientService: update('clientService'),
    setClientAddress: update('clientAddress'),
    setClientZipcode: update('clientZipcode'),
    setClientCity: update('clientCity'),
    createReservation: handleCreateReservation,
    createPendingReservation: handleCreatePendingReservation,
    isLoading,
  }

  return <CreateReservationContextClass.Provider value={context}>{children}</CreateReservationContextClass.Provider>
}
