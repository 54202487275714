import React from 'react'
import { Card, CardContent, Container, Typography } from '@mui/material'

export const CancellationPage = () => {
  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant='h3' align='center' gutterBottom>
            Vous avez annulé votre paiment
          </Typography>

          <Typography variant='h5' align='center' gutterBottom>
            Nous sommes désolés d'apprendre que vous ayez annulé le paiement de votre réservation et
            espérons vous
            revoir très prochainement sur notre site internet.
          </Typography>

          <Typography variant='subtitle1' align='center' gutterBottom>
            Si vous rencontrez des problèmes pour réserver, n'hésitez pas à nous contacter au
            02.40.48.76.31.
          </Typography>

          <Typography variant='subtitle1' align='center' gutterBottom>
            Nous serons ravis de pouvoir vous aider.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}