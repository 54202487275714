import { HttpClient } from '../network/http-client'
import { IGetTransaction } from '../../domain/usecases/get-transaction'
import { Transaction } from '../../domain/entities/transaction'
import { HttpStatusCode } from 'axios'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../domain/errors/unexpected-error'

export class RemoteGetTransaction implements IGetTransaction {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient<Transaction>) {
  }

  async get(): Promise<Transaction> {
    const httpResponse = await this.httpClient.request({
      url: this.baseUrl,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}
