import React from 'react'
import { Typography } from '@mui/material'

export const RejectedTransaction = () => {
  return (
    <>
      <Typography variant='h3' align='center' gutterBottom>
        Votre paiement a été refusé
      </Typography>

      <Typography variant='body1' align='center' gutterBottom>
        Votre transaction a été refusé, nous vous invitons à tenter une nouvelle fois ou à contacter
        votre banque si le problème persiste.
      </Typography>

      <br />

      <Typography variant='body1' align='center' gutterBottom>
        Si vous rencontrez des problèmes pour réserver, n'hésitez pas à nous contacter au 02.40.48.76.31.
      </Typography>

      <Typography variant='body1' align='center' gutterBottom>
        Nous serons ravis de pouvoir vous aider.
      </Typography>
    </>
  )
}