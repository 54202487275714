import { IGetReservation } from '../../../domain/usecases/get-reservation'
import { RemoteGetReservation } from '../../../data/usecases/remote-get-reservation'
import { createApiUrl } from '../http/api-url-factory'
import { createAuthorizeHttpClientDecorator } from '../decorators/authorize-http-client-decorator-factory'

export const createRemoteGetReservation = (reservationId: number): IGetReservation =>
  new RemoteGetReservation(
    reservationId,
    createApiUrl('/me/reservation'),
    createAuthorizeHttpClientDecorator(),
  )
