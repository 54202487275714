import React from 'react'
import { Typography } from '@mui/material'

export const SpaceNoMoreAvailable = () => {
  return (
    <>
      <Typography variant='h3' align='center' gutterBottom>
        La place n'est plus disponible
      </Typography>

      <Typography variant='body1' align='center' gutterBottom>
        Nous somme désolé mais le parking est complet pour la période que vous avez sélectionné.
        <br />
        Vous ne serez pas débité pour cette réservation.
      </Typography>

      <br />

      <Typography variant='body1' align='center' gutterBottom>
        Si vous rencontrez des problèmes pour réserver, n'hésitez pas à nous contacter au 02.40.48.76.31.
      </Typography>

      <Typography variant='body1' align='center' gutterBottom>
        Nous serons ravis de pouvoir vous aider.
      </Typography>
    </>
  )
}