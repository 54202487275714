import React, { useEffect, useState } from 'react'
import { Card, CardContent, CircularProgress, Container } from '@mui/material'
import { ReservationContextProvider, useReservationContext } from '../context-provider/reservation-context-provider'
import { Transaction, TransactionStatus } from '../../../../../domain/entities/transaction'
import { RejectedTransaction } from '../Refusal/rejected-transaction'
import { SpaceNoMoreAvailable } from './space-no-more-available'
import { ConfirmationSummary } from '../Confirmation/confirmation-summary'
import { IGetTransaction } from '../../../../../domain/usecases/get-transaction'
import { IGetReservation } from '../../../../../domain/usecases/get-reservation'

const useTransaction = (getTransaction: IGetTransaction): Transaction | null => {
  const [transaction, setTransaction] = useState<Transaction | null>(null)

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const newTransaction = await getTransaction.get()

        // Stop polling if the transaction is in a final state
        if (newTransaction.status == TransactionStatus.NOT_AUTHORIZED ||
          newTransaction.status == TransactionStatus.CAPTURED ||
          newTransaction.status == TransactionStatus.SPACE_NO_MORE_AVAILABLE) {
          clearInterval(interval)
        }

        setTransaction(value => {
          return JSON.stringify(value) === JSON.stringify(newTransaction) ? value : newTransaction
        })
      } catch (error) {
        console.error(error)
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [getTransaction, setTransaction])

  return transaction
}

const Loading = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

const Content = () => {
  const { isLoading, reservation } = useReservationContext()
  return isLoading || reservation == null ? <Loading /> : <ConfirmationSummary reservation={reservation} />
}

type TransactionContentProps = {
  transaction: Transaction
  getReservation: IGetReservation
}

const TransactionContent = ({
                              transaction,
                              getReservation,
                            }: TransactionContentProps) => {
  switch (transaction?.status) {
    case TransactionStatus.NOT_AUTHORIZED:
      return <RejectedTransaction />
    case TransactionStatus.CAPTURED:
      return (
        <ReservationContextProvider getReservation={getReservation}>
          <Content />
        </ReservationContextProvider>
      )
    case TransactionStatus.SPACE_NO_MORE_AVAILABLE:
      return <SpaceNoMoreAvailable />
    default:
      return <Loading />
  }
}

type TransactionPageProps = {
  getTransaction: IGetTransaction
  getReservation: IGetReservation
}

export const TransactionPage = ({
                                  getTransaction,
                                  getReservation,
                                }: TransactionPageProps) => {
  const transaction = useTransaction(getTransaction)

  return (
    <Container>
      <Card>
        <CardContent>
          <TransactionContent transaction={transaction} getReservation={getReservation} />
        </CardContent>
      </Card>
    </Container>
  )
}
