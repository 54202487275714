import { createApiUrl } from '../http/api-url-factory'
import { createAuthorizeHttpClientDecorator } from '../decorators/authorize-http-client-decorator-factory'
import { RemoteGetTransaction } from '../../../data/usecases/remote-get-transaction'
import { IGetTransaction } from '../../../domain/usecases/get-transaction'

export const createRemoteGetTransaction = (transactionId: number): IGetTransaction =>
  new RemoteGetTransaction(
    createApiUrl(`/me/transaction/${transactionId}`),
    createAuthorizeHttpClientDecorator(),
  )
