import { IUpdateReservation, UpdateReservation } from '../../domain/usecases/update-reservation'
import { HttpClient } from '../network/http-client'
import { HttpStatusCode } from 'axios'
import { UnauthorizedError } from '../../domain/errors/unauthorized-error'
import { UnexpectedError } from '../../domain/errors/unexpected-error'

export class RemoteUpdateReservation implements IUpdateReservation {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {
  }

  async update(params: UpdateReservation.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      body: params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new UnauthorizedError()
      default:
        throw new UnexpectedError()
    }
  }
}