import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import { amber, green } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'
import { makeStyles } from 'tss-react/mui'

export enum Variant {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const useStyles = makeStyles()(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const variantIcon = new Map<string, any>([
  [Variant.SUCCESS, CheckCircleIcon],
  [Variant.WARNING, WarningIcon],
  [Variant.ERROR, ErrorIcon],
  [Variant.INFO, InfoIcon],
])

type SnackbarContentWrapperProps = {
  message: string
  onClose: () => void
  variant: Variant
}

function SnackbarContentWrapper({ message, variant, onClose }: SnackbarContentWrapperProps) {
  const { classes, cx } = useStyles()
  const Icon = variantIcon.get(variant)

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

type Props = {
  open: boolean
  autoHideDuration?: number
  message: string
  variant?: Variant
  onClose: () => void
}

/**
 * Default snackbar.
 */
export default ({ open, autoHideDuration, variant, message, onClose }: Props) => {
  const handleClose = (_event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }

    onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration || 3000}
      onClose={handleClose}
    >
      <div>
        <SnackbarContentWrapper onClose={onClose} variant={variant || Variant.INFO} message={message} />
      </div>
    </Snackbar>
  )
}
