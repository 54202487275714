import { makeStyles } from 'tss-react/mui'
import { Box, Theme, Typography } from '@mui/material'
import { Reservation } from '../../../../../domain/entities/reservation'
import React, { useEffect } from 'react'
import { isBrowser } from '../../../../utils/safe-navigation'
import { isAEK } from '../../../../utils/web-site'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { green } from '@mui/material/colors'

function formatDate(dateStr: any) {
  if (dateStr) {
    return new Date(dateStr).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
  return ''
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: { padding: theme.spacing(2) },
  textSpacing: { marginTop: 64 },
}))

export const ConfirmationSummary = ({ reservation }: {
  reservation: Reservation
}) => {
  const { classes } = useStyles()

  useEffect(() => {
    if (isBrowser() && isAEK() && window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-950149709/uE6PCLXFr9UBEM3EiMUD',
        value: 1.0,
        currency: 'EUR',
        transaction_id: '',
      })
    }
  }, [])

  return (
    <div>
      <Typography variant='h2' align='center' gutterBottom>
        Merci pour votre réservation!
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CheckCircleIcon style={{ color: green[500], width: 70, height: 70 }} />
      </Box>

      <Typography variant='h4' align='center' gutterBottom style={{ marginTop: 16 }}>
        Notre équipe sera ravie de vous accueillir le <b>{formatDate(reservation.deposit)}</b>
      </Typography>

      <Typography variant='h5' align='center' gutterBottom className={classes.textSpacing}>
        Un email de confirmation de réservation vous sera envoyé à <b>{reservation.email}</b>
      </Typography>

      <Typography variant='body1' align='center' gutterBottom className={classes.textSpacing}>
        Vous pourrez consulter à tout moment la statut de votre réservation et télécharger votre facture dans "mes
        reservations" de la rubrique "mon compte" sur notre site.
      </Typography>

      <Typography variant='h3' align='center' gutterBottom className={classes.textSpacing}>
        Merci de votre confiance!
      </Typography>
    </div>
  )
}