import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Step from '../Step'

import { useCreateReservationContext } from './CreateReservationContextProvider'
import { PaymentButton } from './PaymentButton'
import { PaymentAtDepositButton } from './PaymentAtDepositButton'

const useStyles = makeStyles()(_theme => ({
  descriptions: {
    textAlign: 'justify',
  },
}))

export default function ReservationStepPayment(props: any) {
  const { classes } = useStyles()
  const { prices } = useCreateReservationContext()
  const showPaymentButton = prices.isPaymentEnabled
  const showConfirmButton = !prices.isPaymentEnabled || !prices.paymentRequired
  const numberOfButtons = (showPaymentButton ? 1 : 0) + (showConfirmButton ? 1 : 0)

  return (
    <Step stepLabel="Paiement" {...props} stepComplete={true}>
      <Typography variant="h4" gutterBottom>
        {props.name}
      </Typography>
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        {showPaymentButton && (
          <Grid item xs={12} md={12 / numberOfButtons}>
            <PaymentButton fullWidth />
            <p className={classes.descriptions}>
              En cliquant sur le bouton ci dessous, vous allez être redirigé vers l'espace de paiement sécurisé afin de
              procéder au règlement de votre acompte.
            </p>
          </Grid>
        )}
        {showConfirmButton && (
          <Grid item xs={12} md={12 / numberOfButtons}>
            <PaymentAtDepositButton fullWidth />
          </Grid>
        )}
      </Grid>
    </Step>
  )
}
