import React from 'react'
import { Router } from '@reach/router'

import { Layout } from '../theme/layout'
import { NavigationBar } from '../components/navigation-bar'
import { NavigationBarLink } from '../components/navigation-bar-link'
import PublicRoute from '../components/app/public-route'
import PrivateRoute from '../components/app/private-route'
import { LogoutNavigationBarLink } from '../components/app/logout-navigation-bar-link'
import createLogin from '../../../main/factories/pages/login-factory'
import { AccountContextProvider } from '../components/app/context-provider/account-context-provider'
import { useIsProfessional } from '../hooks/use-is-professional'
import { NotificationContextProvider } from '../components/app/context-provider/notification-context-provider'
import createForgotPasswordPage from '../../../main/factories/pages/forgot-password-factory'
import createChangePasswordPage from '../../../main/factories/pages/create-change-password-factory'
import { createRemoteAuthentication } from '../../../main/factories/usecases/remote-authentication-factory'
import {
  AuthorizationContextProvider,
  useAuthorizationContext,
} from '../components/app/context-provider/authorization-context-provider'
import { createRemoteGetCurrentAccount } from '../../../main/factories/usecases/remote-get-current-account-factory'
import createMyAccountPage from '../../../main/factories/pages/my-account-factory'
import createCreateAccountPage from '../../../main/factories/pages/create-account-factory'
import createMyReservationsPage from '../../../main/factories/pages/create-my-reservations-factory'
import createReservationDetailsPage from '../../../main/factories/pages/reservation-page-factory'
import createCreateReservationPage from '../../../main/factories/pages/create-reservation-factory'
import createTransactionPage from '../../../main/factories/pages/create-transaction-factory'
import { ConfirmationPage } from '../components/app/Confirmation/ConfirmationPage'
import { RefusalPage } from '../components/app/Refusal/RefusalPage'
import { CancellationPage } from '../components/app/Cancellation/CancellationPage'

const ApplicationNavigationBar = () => {
  const isProfessional = useIsProfessional()
  const { logged } = useAuthorizationContext()

  return (
    <NavigationBar>
      <NavigationBarLink to='/'>Accueil</NavigationBarLink>
      {logged && [
        <NavigationBarLink key='reservation' to='/app'>
          Nouvelle réservation
        </NavigationBarLink>,
        <NavigationBarLink key='my-reservations' to='/app/reservations'>
          Mes réservations
        </NavigationBarLink>,
        <NavigationBarLink key='profile' to='/app/profile'>
          Mon compte
        </NavigationBarLink>,
        isProfessional ? (
          <NavigationBarLink
            key='user-template'
            to='/app'
            onClick={() => {
              window.open('/aas/document/formulaire abonnement.xlsx', '_blank')
            }}
          >
            Template utilisateur
          </NavigationBarLink>
        ) : null,
        <LogoutNavigationBarLink key='disconnect'>Se deconnecter</LogoutNavigationBarLink>,
      ]}
    </NavigationBar>
  )
}

const AppRouter = () => {
  return (
    <div id='top' style={{ paddingTop: 110 }}>
      <Router basepath='/app'>
        <PrivateRoute path='/' component={createCreateReservationPage} />
        <PublicRoute path='/login' component={createLogin} />
        <PublicRoute path='/forgot-password' component={createForgotPasswordPage} />
        <PublicRoute path='/create-account' component={createCreateAccountPage} />
        <PrivateRoute path='/profile' component={createMyAccountPage} />
        <PrivateRoute path='/change-password' component={createChangePasswordPage} />
        <PrivateRoute path='/reservations' component={createMyReservationsPage} />
        <PrivateRoute path='/reservation' component={createReservationDetailsPage} />
        <PublicRoute path='/transaction/:id' component={createTransactionPage} />
        <PublicRoute path='/confirmation' component={ConfirmationPage} />
        <PublicRoute path='/refusal' component={RefusalPage} />
        <PublicRoute path='/cancellation' component={CancellationPage} />
      </Router>
    </div>
  )
}

const App = () => {
  const remoteAuthentication = createRemoteAuthentication()
  const remoteAccount = createRemoteGetCurrentAccount()

  return (
    <div>
      <Layout>
        <NotificationContextProvider>
          <AuthorizationContextProvider authentication={remoteAuthentication}>
            <AccountContextProvider account={remoteAccount}>
              <div>
                <ApplicationNavigationBar />
              </div>
              <div>
                <AppRouter />
              </div>
            </AccountContextProvider>
          </AuthorizationContextProvider>
        </NotificationContextProvider>
      </Layout>
    </div>
  )
}

export default App
