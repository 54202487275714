import React from 'react'
import { Card, CardContent, CircularProgress, Container } from '@mui/material'
import { NumberParam, useQueryParam } from 'use-query-params'
import { createRemoteGetReservation } from '../../../../../main/factories/usecases/remote-get-reservation-factory'
import { makeStyles } from 'tss-react/mui'
import { ReservationContextProvider, useReservationContext } from '../context-provider/reservation-context-provider'
import { ConfirmationSummary } from './confirmation-summary'

const useStyles = makeStyles()(() => ({
  root: {
    textAlign: 'center',
    maxWidth: '1184px',
    marginTop: 110,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 16,
  },
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

const Content = () => {
  const { isLoading, reservation } = useReservationContext()
  const { classes } = useStyles()

  return isLoading || reservation == null ? (
    <div className={classes.progressBar}>
      <CircularProgress />
    </div>
  ) : (
    <ConfirmationSummary reservation={reservation} />
  )
}

export const ConfirmationPage = () => {
  const [reservationId] = useQueryParam('ref', NumberParam)
  const getReservation = createRemoteGetReservation(reservationId)

  return (
    <Container>
      <Card>
        <CardContent>
          <ReservationContextProvider getReservation={getReservation}>
            <Content />
          </ReservationContextProvider>
        </CardContent>
      </Card>
    </Container>
  )
}