import React from 'react'
import { Card, CardContent, Container } from '@mui/material'
import { RejectedTransaction } from './rejected-transaction'

export const RefusalPage = () => {
  return (
    <Container>
      <Card>
        <CardContent>
            <RejectedTransaction />
        </CardContent>
      </Card>
    </Container>
  )
}