import React, { createContext, useContext, useEffect, useState } from 'react'
import { Reservation } from 'domain/entities/reservation'
import { IGetReservation } from '../../../../../domain/usecases/get-reservation'
import { useNotificationContext } from './notification-context-provider'
import { IUpdateReservation, UpdateReservation } from '../../../../../domain/usecases/update-reservation'
import { Voucher } from '../../../../../domain/usecases/voucher'

export type ReservationContext = {
  isLoading: Boolean
  reservation: Reservation | null
  update: (params: UpdateReservation.Params) => Promise<void>
  openVoucher: () => void
}

const ReservationContextClass = createContext<ReservationContext>({
  isLoading: false,
  reservation: null,
  update: () => Promise.reject(),
  openVoucher: () => {
  },
})

export function useReservationContext(): ReservationContext {
  return useContext(ReservationContextClass)
}

type Props = {
  getReservation: IGetReservation
  updateReservation?: IUpdateReservation
  voucher?: Voucher
  children: React.ReactNode
}

export const ReservationContextProvider = ({
                                             getReservation,
                                             updateReservation,
                                             voucher,
                                             children,
                                           }: Props) => {
  const { notifyError, notifySuccess } = useNotificationContext()
  const [reservation, setReservation] = useState<Reservation | null>(null)
  const [isLoading, setLoading] = useState<Boolean>(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await getReservation.get()
        setReservation(response)
      } catch (error) {
        notifyError('Les informations n\'ont pas pu est chargées.')
      } finally {
        setLoading(false)
      }
    })()
  }, [getReservation])

  const handleUpdate = async (params: UpdateReservation.Params): Promise<void> => {
    try {
      setLoading(true)
      await updateReservation.update(params)
      const response = await getReservation.get()
      setReservation(response)
      notifySuccess('Les informations ont été enregistrées.')
    } catch (error) {
      console.log(error)
      notifyError('Les informations n\'ont pas pu est chargées.')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenVoucher = async () => {
    if (voucher && reservation) {
      await voucher.openVoucher(reservation.id)
    } else {
      console.error('Voucher is not available')
    }
  }

  const context: ReservationContext = {
    isLoading: isLoading,
    reservation,
    update: handleUpdate,
    openVoucher: handleOpenVoucher,
  }

  return <ReservationContextClass.Provider value={context}>{children}</ReservationContextClass.Provider>
}
