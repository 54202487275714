import React, {createContext, useCallback, useContext, useMemo, useState,} from "react"
import Snackbar, {Variant} from "../Snackbar"

export type NotificationContextType = {
    notify: (variant: Variant, message: string) => void
    notifyInfo: (message: string) => void
    notifySuccess: (message: string) => void
    notifyWarning: (message: string) => void
    notifyError: (message: string) => void
}

export const NotificationContext = createContext({})

export function useNotificationContext(): NotificationContextType {
    return useContext(NotificationContext) as NotificationContextType
}

type Notification = {
    variant: Variant
    message: string | null
    duration?: number
}

type Props = {
    children: React.ReactNode
}

export const NotificationContextProvider = ({children}: Props) => {
    const [notification, setNotification] = useState<Notification>({
        variant: Variant.SUCCESS,
        message: null,
    })
    const close = useCallback(
        () => setNotification({...notification, message: null}),
        [notification, setNotification]
    )
    const contextValue = useMemo(
        () => ({
            notify: (variant: Variant, message: string) =>
                setNotification({variant, message: message}),
            notifyInfo: (message: string) =>
                setNotification({variant: Variant.INFO, message: message}),
            notifySuccess: (message: string) =>
                setNotification({variant: Variant.SUCCESS, message: message}),
            notifyWarning: (message: string) =>
                setNotification({variant: Variant.WARNING, message: message}),
            notifyError: (message: string) =>
                setNotification({variant: Variant.ERROR, message: message}),
        }),
        [setNotification]
    )

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
            <Snackbar
                open={!!notification?.message}
                onClose={close}
                message={notification?.message || ""}
                variant={notification?.variant || Variant.INFO}
            />
        </NotificationContext.Provider>
    )
}
