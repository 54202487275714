export class Transaction {
  constructor(
    public readonly id: number,
    public readonly status: TransactionStatus
  ) {}
}

export enum TransactionStatus {
  PENDING_AUTHORIZATION,
  AUTHORIZED,
  NOT_AUTHORIZED,
  CAPTURED,
  SPACE_NO_MORE_AVAILABLE
}
